// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .work-stats .number {
    font-size: 54px;
  }

  .work-stats .desc {
    font-size: 18px;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .jumbotron {
    padding: 3rem 0;
  }

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 24px;
  }

  h5 {
    font-size: 16px;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    font-size: 16px;
    letter-spacing: inherit;
  }

  .list-service {
    position: relative;
    margin-top: 30px;
  }

  .detail-project {
    height: 250px;
  }

  .work-stats .number {
    font-size: 42px;
    line-height: 1.4;
  }

  .work-stats .desc {
    font-size: 18px;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .btn-act {
    width: 100%;
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
}
