@import "./typrography.scss";
@import "./color";

html,
body {
  font-family: "Product Sans", sans-serif;
  letter-spacing: 0.18px;
  color: $black;
}

.logo {
  width: 75px;
}

.nav-link,
.navbar-text {
  font-family: "Product Sans Bold", sans-serif;
  font-size: 18px;
  color: $black;
  line-height: 1.25;
  letter-spacing: 1.2px;
  margin: auto 15px;
}

.tblue {
  color: $blue;
}

.tblack {
  color: $black !important;
}

a {
  font-family: "Product Sans Medium", sans-serif;
  color: $blue;
  transition: 0.3s;
  &:hover,
  :focus {
    color: $hvr-blue;
  }
}

a.cta-link {
  color: $black;
  text-decoration: underline;
  &:hover,
  :focus {
    color: $hvr-blue;
  }
}

.sosmed li {
  display: inline;
}

.tbali {
  color: $bali-hai;
}

.tbold {
  font-family: "Product Sans Medium", sans-serif;
}

h1 {
  font-size: 48px;
  font-family: "Product Sans Bold", sans-serif;
  font-weight: bold;
  letter-spacing: 1.24px;
}

h2 {
  font-size: 42px;
  font-family: "Product Sans Bold", sans-serif;
  letter-spacing: 2px;
}

h3 {
  font-size: 32px;
  font-family: "Product Sans Bold", sans-serif;
  letter-spacing: 1.6px;
}

h5 {
  font-size: 18px;
  line-height: 1.67;
}

h6 {
  font-size: 14px;
}

p {
  line-height: 1.67;
  font-size: 15px;
}

.btn {
  font-family: "Product Sans Medium", sans-serif;
  line-height: 1.22;
  letter-spacing: 0.8px;
  border: none;
  transition: 0.3s;
  &:hover,
  :focus {
    box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.05),
      0 7px 14px rgba(0, 0, 0, 0.1);
  }
}

.btn-blue {
  background: $blue !important;
  color: $white;
  &:hover,
  :focus {
    background: $hvr-blue;
  }
}

.btn-white {
  background: $white !important;
  color: $blue !important;
  border: solid 2px #ececec !important;
  &:hover,
  :focus {
    border: solid 2px $blue;
    background: $white;
  }
}

.btn-act {
  width: 202px;
  height: 50px;
}

.bg-blue {
  background: $blue;
}

.bg-portfolio {
  background: url("../assets/images/bg-portfolio.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.bg-black {
  background: $black;
}

.skills {
  line-height: 2.78;
  letter-spacing: 0.18px;
}

.reactangle {
  width: 50px;
  height: 5px;
  background-color: #ffffff;
}

.row-0 {
  margin-right: 0;
  margin-left: 0;
}

.col-0 {
  padding-right: 0;
  padding-left: 0;
}

.tiny-text {
  line-height: 1.33;
  letter-spacing: 1.8px;
  color: $chalice;
  font-size: 10px;
}

.list-service {
  line-height: 2.15;
  letter-spacing: 0.13px;
  color: $chalice;
  font-size: 12px;
  position: absolute;
  bottom: 10px;
}

.col-container {
  display: table;
  width: 100%;
}

.work-stats .number {
  font-size: 5.375rem;
  font-weight: 700;
  line-height: 0.8em;
  letter-spacing: -0.04em;
  margin-right: 2rem;
  margin-bottom: 0;
}

.work-stats .desc {
  font-size: 20px;
  float: right;
  color: #404040;
}

span.top {
  font-size: 40px;
  position: absolute;
  top: -27px;
}

ul.client-logo {
  list-style: none;
  padding: 20px;
  text-align: center;
}

ul.client-logo li {
  display: inline;
}

ul.client-logo li img {
  max-height: 80px;
  max-width: 1800px;
  padding: 23px 30px;
}

.nav-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.05);
}

.mobile-menu {
  display: none;
  position: fixed;
  background: $black;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  height: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

button.navbar-toggler {
  color: $blue;
  background: none;
  box-shadow: none !important;
  &:hover,
  &:focus {
    color: $blue;
  }
}

.mobile-menu-navigation {
  padding: 0;
  line-height: 5rem;
  font-size: 2.25rem;
  text-align: center;
  & a {
    color: $white !important;
  }
}
