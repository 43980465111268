@font-face {
  font-family: "Product Sans";
  src: url("../assets/fonts/google-sans/ProductSans-Regular.woff")
    format("truetype");
}

@font-face {
  font-family: "Product Sans Bold";
  src: url("../assets/fonts/google-sans/ProductSans-Bold.woff")
    format("truetype");
}

@font-face {
  font-family: "Product Sans Medium";
  src: url("../assets/fonts/google-sans/ProductSans-Medium.woff")
    format("truetype");
}
